import Type from '../../type';
import {getApiController} from '../../resource';
import {IResource} from '../../../../../shared/interfaces/common/resource';
import { IDeleteTempProductPayload, ISaveTempProductPayload, ISearchProductsPayload, ISearchTempProductsPayload } from '../../../../../presentation/redux/actions/interfaces/tempProduct.types';

const SearchProducts = (payload: ISearchProductsPayload, storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/temporary-products/v1.0/SearchProduct?productName=${encodeURIComponent(payload.productName)}&janCode=${payload.janCode != null ? encodeURIComponent(payload.janCode) : ''}`
});

const SaveTempProduct = (payload: ISaveTempProductPayload, storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/temporary-products/v1.0/SaveTemporaryProduct`
});

const DeleteTemporaryProduct = (payload: IDeleteTempProductPayload, storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/temporary-products/v1.0/DeleteTemporaryProduct?id=${payload.id}`
});

const GetCategories = (storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/temporary-products/v1.0/GetCategories`
});

const GetBrands = (storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/temporary-products/v1.0/GetBrands`
});

const SearchTempProducts = (payload: ISearchTempProductsPayload, storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/temporary-products/v1.0/SearchTempProducts?page=${payload.page}&pageSize=${payload.pageSize}&keyword=${encodeURIComponent(payload.keyword)}&status=${payload.status ?? ''}`
});

const ImportBatchProducts = (payload: any, storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/temporary-products/v1.0/ImportBatchProducts`
});

const ImportProductsHistories = (payload: any, storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/temporary-products/v1.0/ImportProductsHistory?page=${payload.page}&pageSize=${payload.pageSize}&startDate=${payload.startDate}&endDate=${payload.endDate}&ignoreLogs=true`
});

export default {
    SearchProducts,
    SaveTempProduct,
    DeleteTemporaryProduct,
    GetCategories,
    GetBrands,
    SearchTempProducts,
    ImportBatchProducts,
    ImportProductsHistories
};