import {SitecoreResource} from '../../../gateway/api/resource';
import ResponseModel from '../../../../models/response';
import { getConfig } from '../../../setting/graphConfig';
import ApiGateway from '../../../gateway/api';
import { IGetFileMitsubishiPayload, IGetMitsubishiUploadHistoryPayload, IImportFilesHistoryPayload } from '../../../../presentation/redux/actions/interfaces/mitsubishi.types';
import { APIResponse } from '../../../../models/response/types';

const importFilesHistory = (payload: IImportFilesHistoryPayload): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const mitsubishiResource = SitecoreResource.Mitsubishi.ImportFilesHistory(payload);   
    console.log(apiGateway);
    
    return apiGateway.doGetRequest(mitsubishiResource);
};

const importFiles = (payload: any): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const mitsubishiResource = SitecoreResource.Mitsubishi.ImportFiles();
    return apiGateway.doPostRequest(mitsubishiResource, payload);
};

export const getFileImportMitsubishi = (payload: IGetFileMitsubishiPayload): Promise<ResponseModel<APIResponse<string>>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const productResource = SitecoreResource.Mitsubishi.GetFileImportMitsubishi(payload);
    return apiGateway.doGetRequest(productResource);
};

const getFileDetailError = (payload: IGetMitsubishiUploadHistoryPayload): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const productResource = SitecoreResource.Mitsubishi.GetFileDetailError(payload);
    return apiGateway.doGetRequest(productResource);
};

export default {
    importFilesHistory,
    importFiles,
    getFileImportMitsubishi,
    getFileDetailError
};
