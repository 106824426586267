import { IAction } from "../../../../shared/interfaces/common";
import { IDeleteTempProductPayload, ISaveTempProductPayload, ISearchProductsPayload, ISearchTempProductsPayload } from "../interfaces/tempProduct.types";

// Action Types
export enum TempProductActionTypes {
  SEARCH_TEMP_PRODUCTS = "[TempProduct] SEARCH_TEMP_PRODUCTS",
  SEARCH_TEMP_PRODUCTS_SUCCESS = "[TempProduct] SEARCH_TEMP_PRODUCTS_SUCCESS",
  SEARCH_TEMP_PRODUCTS_FAIL = "[TempProduct] SEARCH_TEMP_PRODUCTS_FAIL",

  SEARCH_PRODUCTS = "[TempProduct] SEARCH_PRODUCTS",
  SEARCH_PRODUCTS_SUCCESS = "[TempProduct] SEARCH_PRODUCTS_SUCCESS",
  SEARCH_PRODUCTS_FAIL = "[TempProduct] SEARCH_PRODUCTS_FAIL",

  GET_CATEGORIES = "[TempProduct] GET_CATEGORIES",
  GET_CATEGORIES_SUCCESS = "[TempProduct] GET_CATEGORIES_SUCCESS",
  GET_CATEGORIES_FAIL = "[TempProduct] GET_CATEGORIES_FAIL",

  GET_BRANDS = "[TempProduct] GET_BRANDS",
  GET_BRANDS_SUCCESS = "[TempProduct] GET_BRANDS_SUCCESS",
  GET_BRANDS_FAIL = "[TempProduct] GET_BRANDS_FAIL",

  DELETE_TEMP_PRODUCT = "[TempProduct] DELETE_TEMP_PRODUCT",
  DELETE_TEMP_PRODUCT_SUCCESS = "[TempProduct] DELETE_TEMP_PRODUCT_SUCCESS",
  DELETE_TEMP_PRODUCT_FAIL = "[TempProduct] DELETE_TEMP_PRODUCT_FAIL",

  SAVE_TEMP_PRODUCT = "[TempProduct] SAVE_TEMP_PRODUCT",
  SAVE_TEMP_PRODUCT_SUCCESS = "[TempProduct] SAVE_TEMP_PRODUCT_SUCCESS",
  SAVE_TEMP_PRODUCT_FAIL = "[TempProduct] SAVE_TEMP_PRODUCT_FAIL",

  IMPORT_BATCH_PRODUCTS = "[TempProduct] IMPORT_BATCH_PRODUCTS",
  IMPORT_BATCH_PRODUCTS_SUCCESS = "[TempProduct] IMPORT_BATCH_PRODUCTS_SUCCESS",
  IMPORT_BATCH_PRODUCTS_FAIL = "[TempProduct] IMPORT_BATCH_PRODUCTS_FAIL",

  IMPORT_PRODUCT_HISTORIES = "[TempProduct] IMPORT_PRODUCT_HISTORIES",
  IMPORT_PRODUCT_HISTORIES_SUCCESS = "[TempProduct] IMPORT_PRODUCT_HISTORIES_SUCCESS",
  IMPORT_PRODUCT_HISTORIES_FAIL = "[TempProduct] IMPORT_PRODUCT_HISTORIES_FAIL",

}

export const searchTempProducts = (
  payload?: ISearchTempProductsPayload
): IAction<any> => ({
  type: TempProductActionTypes.SEARCH_TEMP_PRODUCTS,
  payload,
});

export const searchTempProductsSuccess = (
  payload?: any
): IAction<any> => ({
  type: TempProductActionTypes.SEARCH_TEMP_PRODUCTS_SUCCESS,
  payload,
});

export const searchTempProductsFail = (
  error: any
): IAction<any> => ({
  type: TempProductActionTypes.SEARCH_TEMP_PRODUCTS_FAIL,
  error,
});

export const searchProducts = (
  payload?: ISearchProductsPayload
): IAction<any> => ({
  type: TempProductActionTypes.SEARCH_PRODUCTS,
  payload,
});

export const searchProductsSuccess = (
  payload?: any
): IAction<any> => ({
  type: TempProductActionTypes.SEARCH_PRODUCTS_SUCCESS,
  payload,
});

export const searchProductsFail = (
  error: any
): IAction<any> => ({
  type: TempProductActionTypes.SEARCH_PRODUCTS_FAIL,
  error,
});

export const getCategories = (
  payload?: any
): IAction<any> => ({
  type: TempProductActionTypes.GET_CATEGORIES,
  payload,
});

export const getCategoriesSuccess = (
  payload?: any
): IAction<any> => ({
  type: TempProductActionTypes.GET_CATEGORIES_SUCCESS,
  payload,
});

export const getCategoriesFail = (
  error: any
): IAction<any> => ({
  type: TempProductActionTypes.GET_CATEGORIES_FAIL,
  error,
});

export const getBrands = (
  payload?: any
): IAction<any> => ({
  type: TempProductActionTypes.GET_BRANDS,
  payload,
});

export const getBrandsSuccess = (
  payload?: any
): IAction<any> => ({
  type: TempProductActionTypes.GET_BRANDS_SUCCESS,
  payload,
});

export const getBrandsFail = (
  error: any
): IAction<any> => ({
  type: TempProductActionTypes.GET_BRANDS_FAIL,
  error,
});

export const deleteTempProduct = (
  payload?: IDeleteTempProductPayload
): IAction<any> => ({
  type: TempProductActionTypes.DELETE_TEMP_PRODUCT,
  payload,
});

export const deleteTempProductSuccess = (
  payload?: any
): IAction<any> => ({
  type: TempProductActionTypes.DELETE_TEMP_PRODUCT_SUCCESS,
  payload,
});

export const deleteTempProductFail = (
  error: any
): IAction<any> => ({
  type: TempProductActionTypes.DELETE_TEMP_PRODUCT_FAIL,
  error,
});

export const saveTempProduct = (
  payload?: ISaveTempProductPayload
): IAction<any> => ({
  type: TempProductActionTypes.SAVE_TEMP_PRODUCT,
  payload,
});

export const saveTempProductSuccess = (
  payload?: any
): IAction<any> => ({
  type: TempProductActionTypes.SAVE_TEMP_PRODUCT_SUCCESS,
  payload,
});

export const saveTempProductFail = (
  error: any
): IAction<any> => ({
  type: TempProductActionTypes.SAVE_TEMP_PRODUCT_FAIL,
  error,
});

export const importBatchProducts = (payload: any): IAction<any> => ({
  type: TempProductActionTypes.IMPORT_BATCH_PRODUCTS,
  payload
});

export const importBatchProductsSuccess = (payload?: any): IAction<any> => ({
  type: TempProductActionTypes.IMPORT_BATCH_PRODUCTS_SUCCESS,
  payload,
});

export const importBatchProductsFail = (error: any): IAction<any> => ({
  type: TempProductActionTypes.IMPORT_BATCH_PRODUCTS_FAIL,
  error,
});

export const importProductsHistories = (payload: any): IAction<any> => ({
  type: TempProductActionTypes.IMPORT_PRODUCT_HISTORIES,
  payload
});

export const importProductsHistoriesSuccess = (payload?: any): IAction<any> => ({
  type: TempProductActionTypes.IMPORT_PRODUCT_HISTORIES_SUCCESS,
  payload,
});

export const importProductsHistoriesFail = (error: any): IAction<any> => ({
  type: TempProductActionTypes.IMPORT_PRODUCT_HISTORIES_FAIL,
  error,
});