import { IAction } from "../../../../shared/interfaces/common";
import { SettingNotificationPayload } from "../types/notification-types";

// Notifications Action Types
export enum NotificationActionTypes {
  SAVE_NOTIFICATION = "[Notifications] SAVE_NOTIFICATION",
  SAVE_NOTIFICATION_SUCCESS = "[Notifications] SAVE_NOTIFICATION_SUCCESS",
  SAVE_NOTIFICATION_FAIL = "[Notifications] SAVE_NOTIFICATION_FAIL",

  SAVE_PARTIAL_NOTIFICATION = "[Notifications] SAVE_PARTIAL_NOTIFICATION",
  SAVE_PARTIAL_NOTIFICATION_SUCCESS = "[Notifications] SAVE_PARTIAL_NOTIFICATION_SUCCESS",
  SAVE_PARTIAL_NOTIFICATION_FAIL = "[Notifications] SAVE_PARTIAL_NOTIFICATION_FAIL",

  REGISTER_PRICE_CHANGES = "[Notifications] REGISTER_PRICE_CHANGES",
  REGISTER_PRICE_CHANGES_SUCCESS = "[Notifications] REGISTER_PRICE_CHANGES_SUCCESS",
  REGISTER_PRICE_CHANGES_FAIL = "[Notifications] REGISTER_PRICE_CHANGES_FAIL",

  PUSH_NOTIFICATION = "[Notifications] PUSH_NOTIFICATION",
  PUSH_NOTIFICATION_SUCCESS = "[Notifications] PUSH_NOTIFICATION_SUCCESS",
  PUSH_NOTIFICATION_FAIL = "[Notifications] PUSH_NOTIFICATION_FAIL",

  SEARCH_BRANDS = "[Notifications] SEARCH_BRANDS",
  SEARCH_BRANDS_SUCCESS = "[Notifications] SEARCH_BRANDS_SUCCESS",
  SEARCH_BRANDS_FAIL = "[Notifications] SEARCH_BRANDS_FAIL",

  SEARCH_BANNERS = "[Notifications] SEARCH_BANNERS",
  SEARCH_BANNERS_SUCCESS = "[Notifications] SEARCH_BANNERS_SUCCESS",
  SEARCH_BANNERS_FAIL = "[Notifications] SEARCH_BANNERS_FAIL",

  SEARCH_NOTIFICATIONS = "[Notifications] SEARCH_NOTIFICATIONS",
  SEARCH_NOTIFICATIONS_SUCCESS = "[Notifications] SEARCH_NOTIFICATIONS_SUCCESS",
  SEARCH_NOTIFICATIONS_FAIL = "[Notifications] SEARCH_NOTIFICATIONS_FAIL",

  SEARCH_PRICE_CHANGES = "[Notifications] SEARCH_PRICE_CHANGES",
  SEARCH_PRICE_CHANGES_SUCCESS = "[Notifications] SEARCH_PRICE_CHANGES_SUCCESS",
  SEARCH_PRICE_CHANGES_FAIL = "[Notifications] SEARCH_PRICE_CHANGES_FAIL",

  SEARCH_TAGS = "[Notifications] SEARCH_TAGS",
  SEARCH_TAGS_SUCCESS = "[Notifications] SEARCH_TAGS_SUCCESS",
  SEARCH_TAGS_FAIL = "[Notifications] SEARCH_TAGS_FAIL",

  DELETE_TAGS = "[Notifications] DELETE_TAGS",
  DELETE_TAGS_SUCCESS = "[Notifications] DELETE_TAGS_SUCCESS",
  DELETE_TAGS_FAIL = "[Notifications] DELETE_TAGS_FAIL",

  GET_CATEGORIES = "[Notifications] GET_CATEGORIES",
  GET_CATEGORIES_SUCCESS = "[Notifications] GET_CATEGORIES_SUCCESS",
  GET_CATEGORIES_FAIL = "[Notifications] GET_CATEGORIES_FAIL",

  ADD_TAGS_SAKAYAS = "[Notifications] ADD_TAGS_SAKAYAS",
  ADD_TAGS_SAKAYAS_SUCCESS = "[Notifications] ADD_TAGS_SAKAYAS_SUCCESS",
  ADD_TAGS_SAKAYAS_FAIL = "[Notifications] ADD_TAGS_SAKAYAS_FAIL",

  UPDATE_TAGS_SAKAYAS = "[Notifications] UPDATE_TAGS_SAKAYAS",
  UPDATE_TAGS_SAKAYAS_SUCCESS = "[Notifications] UPDATE_TAGS_SAKAYAS_SUCCESS",
  UPDATE_TAGS_SAKAYAS_FAIL = "[Notifications] UPDATE_TAGS_SAKAYAS_FAIL",

  ADD_TAGS_CUSTOMERS = "[Notifications] ADD_TAGS_CUSTOMERS",
  ADD_TAGS_CUSTOMERS_SUCCESS = "[Notifications] ADD_TAGS_CUSTOMERS_SUCCESS",
  ADD_TAGS_CUSTOMERS_FAIL = "[Notifications] ADD_TAGS_CUSTOMERS_FAIL",

  DELETE_TAG_FOR_CUSTOMER = "[Notifications] DELETE_TAG_FOR_CUSTOMER",
  DELETE_TAG_FOR_CUSTOMER_SUCCESS = "[Notifications] DELETE_TAG_FOR_CUSTOMER_SUCCESS",
  DELETE_TAG_FOR_CUSTOMER_FAIL = "[Notifications] DELETE_TAG_FOR_CUSTOMER_FAIL",

  IMPORT_CUSTOMER_TAGS = "[Notifications] IMPORT_CUSTOMER_TAGS",
  IMPORT_CUSTOMER_TAGS_SUCCESS = "[Notifications] IMPORT_CUSTOMER_TAGS_SUCCESS",
  IMPORT_CUSTOMER_TAGS_FAIL = "[Notifications] IMPORT_CUSTOMER_TAGS_FAIL",

  POPULATE_ADDRESS = "[Notifications] POPULATE_ADDRESS",
  POPULATE_ADDRESS_SUCCESS = "[Notifications] POPULATE_ADDRESS_SUCCESS",
  POPULATE_ADDRESS_FAIL = "[Notifications] POPULATE_ADDRESS_FAIL",

  GET_ORDER_OF_PRODUCT = "[Notifications] GET_ORDER_OF_PRODUCT",
  GET_ORDER_OF_PRODUCT_SUCCESS = "[Notifications] GET_ORDER_OF_PRODUCT_SUCCESS",
  GET_ORDER_OF_PRODUCT_FAIL = "[Notifications] GET_ORDER_OF_PRODUCT_FAIL",

  GET_PRICE_CHANGE_LINES = "[Notifications] GET_PRICE_CHANGE_LINES",
  GET_PRICE_CHANGE_LINES_SUCCESS = "[Notifications] GET_PRICE_CHANGE_LINES_SUCCESS",
  GET_PRICE_CHANGE_LINES_FAIL = "[Notifications] GET_PRICE_CHANGE_LINES_FAIL",

  SEARCH_DISCONTINUED_PRODUCTS = "[Notifications] SEARCH_DISCONTINUED_PRODUCTS",
  SEARCH_DISCONTINUED_PRODUCTS_SUCCESS = "[Notifications] SEARCH_DISCONTINUED_PRODUCTS_SUCCESS",
  SEARCHT_DISCONTINUED_PRODUCTS_FAIL = "[Notifications] SEARCHT_DISCONTINUED_PRODUCTS_FAIL",

  SEARCH_READY_DISCONTINUED_PRODUCTS = "[Notifications] SEARCH_READY_DISCONTINUED_PRODUCTS",
  SEARCH_READY_DISCONTINUED_PRODUCTS_SUCCESS = "[Notifications] SEARCH_READY_DISCONTINUED_PRODUCTS_SUCCESS",
  SEARCHT_READY_DISCONTINUED_PRODUCTS_FAIL = "[Notifications] SEARCHT_READY_DISCONTINUED_PRODUCTS_FAIL",

  GET_DISCONTINUED_PRODUCTS = "[Notifications] GET_DISCONTINUED_PRODUCTS",
  GET_DISCONTINUED_PRODUCTS_SUCCESS = "[Notifications] GET_DISCONTINUED_PRODUCTS_SUCCESS",
  GET_DISCONTINUED_PRODUCTS_FAIL = "[Notifications] GET_DISCONTINUED_PRODUCTS_FAIL",

  HISTORY_DISCONTINUED_PRODUCTS = "[Notifications] HISTORY_DISCONTINUED_PRODUCTS",
  HISTORY_DISCONTINUED_PRODUCTS_SUCCESS = "[Notifications] HISTORY_DISCONTINUED_PRODUCTS_SUCCESS",
  HISTORY_DISCONTINUED_PRODUCTS_FAIL = "[Notifications] HISTORY_DISCONTINUED_PRODUCTS_FAIL",

  COUNT_WARNING_PRODUCTS = "[Notifications] COUNT_WARNING_PRODUCTS",
  COUNT_WARNING_PRODUCTS_SUCCESS = "[Notifications] COUNT_WARNING_PRODUCTS_SUCCESS",
  COUNT_WARNING_PRODUCTS_FAIL = "[Notifications] COUNT_WARNING_PRODUCTS_FAIL",

  CREATE_DISCONTINUED_PRODUCTS = "[Notifications] CREATE_DISCONTINUED_PRODUCTS",
  CREATE_DISCONTINUED_PRODUCTS_SUCCESS = "[Notifications] CREATE_DISCONTINUED_PRODUCTS_SUCCESS",
  CREATE_DISCONTINUED_PRODUCTS_FAIL = "[Notifications] CREATE_DISCONTINUED_PRODUCTS_FAIL",

  UPLOAD_DISCONTINUED_PRODUCTS = "[Notifications] UPLOAD_DISCONTINUED_PRODUCTS",
  UPLOAD_DISCONTINUED_PRODUCTS_SUCCESS = "[Notifications] UPLOAD_DISCONTINUED_PRODUCTS_SUCCESS",
  UPLOAD_DISCONTINUED_PRODUCTS_FAIL = "[Notifications] UPLOAD_DISCONTINUED_PRODUCTS_FAIL",

  UPDATE_DISCONTINUED_LINE = "[Notifications] UPDATE_DISCONTINUED_LINE",
  UPDATE_DISCONTINUED_LINE_SUCCESS = "[Notifications] UPDATE_DISCONTINUED_LINE_SUCCESS",
  UPDATE_DISCONTINUED_LINE_FAIL = "[Notifications] UPDATE_DISCONTINUED_LINE_FAIL",

  DELETE_DISCONTINUED_LINES = "[Notifications] DELETE_DISCONTINUED_LINES",
  DELETE_DISCONTINUED_LINES_SUCCESS = "[Notifications] DELETE_DISCONTINUED_LINES_SUCCESS",
  DELETE_DISCONTINUED_LINES_FAIL = "[Notifications] DELETE_DISCONTINUED_LINES_FAIL",

  SAVE_PROMOTION = "[Notifications] SAVE_PROMOTION",
  SAVE_PROMOTION_SUCCESS = "[Notifications] SAVE_PROMOTION_SUCCESS",
  SAVE_PROMOTION_FAIL = "[Notifications] SAVE_PROMOTION_FAIL",

  CALCULATE_ANNOUNCEMENT_TARGET = "[Notifications] CALCULATE_ANNOUNCEMENT_TARGET",
  CALCULATE_ANNOUNCEMENT_TARGET_SUCCESS = "[Notifications] CALCULATE_ANNOUNCEMENT_TARGET_SUCCESS",
  CALCULATE_ANNOUNCEMENT_TARGET_FAIL = "[Notifications] CALCULATE_ANNOUNCEMENT_TARGET_FAIL",

  DOWNLOAD_DISCONTINUED_LINES = "[Notifications] DOWNLOAD_DISCONTINUED_LINES",
  DOWNLOAD_DISCONTINUED_LINES_SUCCESS = "[Notifications] DOWNLOAD_DISCONTINUED_LINES_SUCCESS",
  DOWNLOAD_DISCONTINUED_LINES_FAIL = "[Notifications] DOWNLOAD_DISCONTINUED_LINES_FAIL",

  UPLOAD_PRICE_NOFITICATION = "[Notifications] UPLOAD_PRICE_NOFITICATION",
  UPLOAD_PRICE_NOFITICATION_SUCCESS = "[Notifications] UPLOAD_PRICE_NOFITICATION_SUCCESS",
  UPLOAD_PRICE_NOFITICATION_FAIL = "[Notifications] UPLOAD_PRICE_NOFITICATION_FAIL",

  IMPORT_NOTIFICATION_HISTORY = "[Notifications] IMPORT_NOTIFICATION_HISTORY",
  IMPORT_NOTIFICATION_HISTORY_SUCCESS = "[Notifications] IMPORT_NOTIFICATION_HISTORY_SUCCESS",
  IMPORT_NOTIFICATION_HISTORY_FAIL = "[Notifications] IMPORT_NOTIFICATION_HISTORY_FAIL",

  DOWNLOAD_DRAFT_TO_FILE = "[Notifications] DOWNLOAD_DRAFT_TO_FILE",
  DOWNLOAD_DRAFT_TO_FILE_SUCCESS = "[Notifications] DOWNLOAD_DRAFT_TO_FILE_SUCCESS",
  DOWNLOAD_DRAFT_TO_FILE_FAIL = "[Notifications] DOWNLOAD_DRAFT_TO_FILE_FAIL",

  GET_DRAFT_RECORD = "[Notifications] GET_DRAFT_RECORD",
  GET_DRAFT_RECORD_SUCCESS = "[Notifications] GET_DRAFT_RECORD_SUCCESS",
  GET_DRAFT_RECORD_FAIL = "[Notifications] GET_DRAFT_RECORD_FAIL",

  GET_NOTIFICATION_DETAIL = "[Notifications] GET_NOTIFICATION_DETAIL",
  GET_NOTIFICATION_DETAIL_SUCCESS = "[Notifications] GET_NOTIFICATION_DETAIL_SUCCESS",
  GET_NOTIFICATION_DETAIL_FAIL = "[Notifications] GET_NOTIFICATION_DETAIL_FAIL",

  DELETE_DRAFT_RECORD = "[Notifications] DELETE_DRAFT_RECORD",
  DELETE_DRAFT_RECORD_SUCCESS = "[Notifications] DELETE_DRAFT_RECORD_SUCCESS",
  DELETE_DRAFT_RECORD_FAIL = "[Notifications] DELETE_DRAFT_RECORD_FAIL",

  DELETE_NOTIFICATIONS = "[Notifications] DELETE_NOTIFICATIONS",
  DELETE_NOTIFICATIONS_SUCCESS = "[Notifications] DELETE_NOTIFICATIONS_SUCCESS",
  DELETE_NOTIFICATIONS_FAIL = "[Notifications] DELETE_NOTIFICATIONS_FAIL",

  UPDATE_NOTIFICATION_STATUS = "[Notifications] UPDATE_NOTIFICATION_STATUS",
  UPDATE_NOTIFICATION_STATUS_SUCCESS = "[Notifications] UPDATE_NOTIFICATION_STATUS_SUCCESS",
  UPDATE_NOTIFICATION_STATUS_FAIL = "[Notifications] UPDATE_NOTIFICATION_STATUS_FAIL",

  GET_SEGMENTS = "[Notifications] GET_SEGMENTS",
  GET_SEGMENTS_SUCCESS = "[Notifications] GET_SEGMENTS_SUCCESS",
  SEARCH_SEGMENTS_FAIL = "[Notifications] SEARCH_SEGMENTS_FAIL",

  ADD_SEGMENT = "[Notifications] ADD_SEGMENT",
  ADD_SEGMENT_SUCCESS = "[Notifications] ADD_SEGMENT_SUCCESS",
  ADD_SEGMENT_FAIL = "[Notifications] ADD_SEGMENT_FAIL",

  CACULATE_AUDIENCE_SIZE = "[Notifications] CACULATE_AUDIENCE_SIZE",
  CACULATE_AUDIENCE_SIZE_SUCCESS = "[Notifications] CACULATE_AUDIENCE_SIZE_SUCCESS",
  CACULATE_AUDIENCE_SIZE_FAIL = "[Notifications] CACULATE_AUDIENCE_SIZE_FAIL",

  UPDATE_SEGMENT = "[Notifications] UPDATE_SEGMENT",
  UPDATE_SEGMENT_SUCCESS = "[Notifications] UPDATE_SEGMENT_SUCCESS",
  UPDATE_SEGMENT_FAIL = "[Notifications] UPDATE_SEGMENT_FAIL",

  DELETE_SEGMENT = "[Notifications] DELETE_SEGMENT",
  DELETE_SEGMENT_SUCCESS = "[Notifications] DELETE_SEGMENT_SUCCESS",
  DELETE_SEGMENT_FAIL = "[Notifications] DELETE_SEGMENT_FAIL",

  SETTING_NOTIFICATIONS = "[Notifications] SETTING_NOTIFICATIONS",
  SETTING_NOTIFICATIONS_SUCCESS = "[Notifications] SETTING_NOTIFICATIONS_SUCCESS",
  SETTING_NOTIFICATIONS_FAIL = "[Notifications] SETTING_NOTIFICATIONS_FAIL",

  GET_SETTING_NOTIFICATIONS = "[Notifications] GET_SETTING_NOTIFICATIONS",
  GET_SETTING_NOTIFICATIONS_SUCCESS = "[Notifications] GET_SETTING_NOTIFICATIONS_SUCCESS",
  GET_SETTING_NOTIFICATIONS_FAIL = "[Notifications] GET_SETTING_NOTIFICATIONS_FAIL",

}

// save noti to mobile
export const saveNotification = (payload: any): IAction<any> => ({
  type: NotificationActionTypes.SAVE_NOTIFICATION,
  payload,
});

export const saveNotificationSuccess = (payload?: any): IAction<any> => ({
  type: NotificationActionTypes.SAVE_NOTIFICATION_SUCCESS,
  payload,
});

export const saveNotificationFail = (error: any): IAction<any> => ({
  type: NotificationActionTypes.SAVE_NOTIFICATION_FAIL,
  error,
});

// save noti to mobile
export const savePartialNotification = (payload: any): IAction<any> => ({
  type: NotificationActionTypes.SAVE_PARTIAL_NOTIFICATION,
  payload,
});

export const savePartialNotificationSuccess = (payload?: any): IAction<any> => ({
  type: NotificationActionTypes.SAVE_PARTIAL_NOTIFICATION_SUCCESS,
  payload,
});

export const savePartialNotificationFail = (error: any): IAction<any> => ({
  type: NotificationActionTypes.SAVE_PARTIAL_NOTIFICATION_FAIL,
  error,
});

// register Price Changes
export const registerPriceChanges = (payload: any): IAction<any> => ({
  type: NotificationActionTypes.REGISTER_PRICE_CHANGES,
  payload,
});

export const registerPriceChangesSuccess = (payload?: any): IAction<any> => ({
  type: NotificationActionTypes.REGISTER_PRICE_CHANGES_SUCCESS,
  payload,
});

export const registerPriceChangesFail = (error: any): IAction<any> => ({
  type: NotificationActionTypes.REGISTER_PRICE_CHANGES_FAIL,
  error,
});

// Push noti to mobile
export const pushNotification = (payload: any): IAction<any> => ({
  type: NotificationActionTypes.PUSH_NOTIFICATION,
  payload,
});

export const pushNotificationSuccess = (payload?: any): IAction<any> => ({
  type: NotificationActionTypes.PUSH_NOTIFICATION_SUCCESS,
  payload,
});

export const pushNotificationFail = (error: any): IAction<any> => ({
  type: NotificationActionTypes.PUSH_NOTIFICATION_FAIL,
  error,
});
// search brands
export const searchBrands = (payload: any): IAction<any> => ({
  type: NotificationActionTypes.SEARCH_BRANDS,
  payload,
});

export const searchBrandsSuccess = (payload?: any): IAction<any> => ({
  type: NotificationActionTypes.SEARCH_BRANDS_SUCCESS,
  payload,
});

export const searchBrandsFail = (error: any): IAction<any> => ({
  type: NotificationActionTypes.SEARCH_BRANDS_FAIL,
  error,
});

// search banners
export const searchBanners = (payload: any): IAction<any> => ({
  type: NotificationActionTypes.SEARCH_BANNERS,
  payload,
});

export const searchBannersSuccess = (payload?: any): IAction<any> => ({
  type: NotificationActionTypes.SEARCH_BANNERS_SUCCESS,
  payload,
});

export const searchBannersFail = (error: any): IAction<any> => ({
  type: NotificationActionTypes.SEARCH_BANNERS_FAIL,
  error,
});

// search notifications
export const searchNotifications = (payload: any): IAction<any> => ({
  type: NotificationActionTypes.SEARCH_NOTIFICATIONS,
  payload,
});

export const searchNotificationsSuccess = (payload?: any): IAction<any> => ({
  type: NotificationActionTypes.SEARCH_NOTIFICATIONS_SUCCESS,
  payload,
});

export const searchNotificationsFail = (error: any): IAction<any> => ({
  type: NotificationActionTypes.SEARCH_NOTIFICATIONS_FAIL,
  error,
});
// search price changes
export const searchPriceChanges = (payload: any): IAction<any> => ({
  type: NotificationActionTypes.SEARCH_PRICE_CHANGES,
  payload,
});

export const searchPriceChangesSuccess = (payload?: any): IAction<any> => ({
  type: NotificationActionTypes.SEARCH_PRICE_CHANGES_SUCCESS,
  payload,
});

export const searchPriceChangesFail = (error: any): IAction<any> => ({
  type: NotificationActionTypes.SEARCH_PRICE_CHANGES_FAIL,
  error,
});

// search tags
export const searchTags = (payload: any): IAction<any> => ({
  type: NotificationActionTypes.SEARCH_TAGS,
  payload,
});

export const searchTagsSuccess = (payload?: any): IAction<any> => ({
  type: NotificationActionTypes.SEARCH_TAGS_SUCCESS,
  payload,
});

export const searchTagsFail = (error: any): IAction<any> => ({
  type: NotificationActionTypes.SEARCH_TAGS_FAIL,
  error,
});
// search tags
export const deleteTags = (payload: any): IAction<any> => ({
  type: NotificationActionTypes.DELETE_TAGS,
  payload,
});

export const deleteTagsSuccess = (payload?: any): IAction<any> => ({
  type: NotificationActionTypes.DELETE_TAGS_SUCCESS,
  payload,
});

export const deleteTagsFail = (error: any): IAction<any> => ({
  type: NotificationActionTypes.DELETE_TAGS_FAIL,
  error,
});
// get categories
export const getCategories = (): IAction<any> => ({
  type: NotificationActionTypes.GET_CATEGORIES
});

export const getCategoriesSuccess = (payload?: any): IAction<any> => ({
  type: NotificationActionTypes.GET_CATEGORIES_SUCCESS,
  payload,
});

export const getCategoriesFail = (error: any): IAction<any> => ({
  type: NotificationActionTypes.GET_CATEGORIES_FAIL,
  error,
});

// add tag for sakayas
export const addTagsForSakaya = (payload: any): IAction<any> => ({
  type: NotificationActionTypes.ADD_TAGS_SAKAYAS,
  payload,
});

export const addTagsForSakayaSuccess = (payload?: any): IAction<any> => ({
  type: NotificationActionTypes.ADD_TAGS_SAKAYAS_SUCCESS,
  payload,
});

export const addTagsForSakayaFail = (error: any): IAction<any> => ({
  type: NotificationActionTypes.ADD_TAGS_SAKAYAS_FAIL,
  error,
});

// update tag for sakayas
export const updateTagsForSakaya = (payload: any): IAction<any> => ({
  type: NotificationActionTypes.UPDATE_TAGS_SAKAYAS,
  payload,
});

export const updateTagsForSakayaSuccess = (payload?: any): IAction<any> => ({
  type: NotificationActionTypes.UPDATE_TAGS_SAKAYAS_SUCCESS,
  payload,
});

export const updateTagsForSakayaFail = (error: any): IAction<any> => ({
  type: NotificationActionTypes.UPDATE_TAGS_SAKAYAS_FAIL,
  error,
});

// add tag for customers
export const addTagForCustomer = (payload: any): IAction<any> => ({
  type: NotificationActionTypes.ADD_TAGS_CUSTOMERS,
  payload,
});

export const addTagForCustomerSuccess = (payload?: any): IAction<any> => ({
  type: NotificationActionTypes.ADD_TAGS_CUSTOMERS_SUCCESS,
  payload,
});

export const addTagForCustomerFail = (error: any): IAction<any> => ({
  type: NotificationActionTypes.ADD_TAGS_CUSTOMERS_FAIL,
  error,
});

// delete tag for customers
export const deleteTagForCustomer = (payload: any): IAction<any> => ({
  type: NotificationActionTypes.DELETE_TAG_FOR_CUSTOMER,
  payload,
});

export const deleteTagForCustomerSuccess = (payload?: any): IAction<any> => ({
  type: NotificationActionTypes.DELETE_TAG_FOR_CUSTOMER_SUCCESS,
  payload,
});

export const deleteTagForCustomerFail = (error: any): IAction<any> => ({
  type: NotificationActionTypes.ADD_TAGS_CUSTOMERS_FAIL,
  error,
});

// import customer tagsa
export const importCustomerTags = (payload?: any): IAction<any> => ({
  type: NotificationActionTypes.IMPORT_CUSTOMER_TAGS,
  payload
});

export const importCustomerTagsSuccess = (payload?: any): IAction<any> => ({
  type: NotificationActionTypes.IMPORT_CUSTOMER_TAGS_SUCCESS,
  payload,
});

export const importCustomerTagsFail = (error: any): IAction<any> => ({
  type: NotificationActionTypes.IMPORT_CUSTOMER_TAGS_FAIL,
  error,
});
// populate address
export const populateAddress = (payload?: any): IAction<any> => ({
  type: NotificationActionTypes.POPULATE_ADDRESS,
  payload
});

export const populateAddressSuccess = (payload?: any): IAction<any> => ({
  type: NotificationActionTypes.POPULATE_ADDRESS_SUCCESS,
  payload,
});

export const populateAddressFail = (error: any): IAction<any> => ({
  type: NotificationActionTypes.POPULATE_ADDRESS_FAIL,
  error,
});

// get order of product
export const getOrderOfProduct = (payload?: any): IAction<any> => ({
  type: NotificationActionTypes.GET_ORDER_OF_PRODUCT,
  payload
});

export const getOrderOfProductSuccess = (payload?: any): IAction<any> => ({
  type: NotificationActionTypes.GET_ORDER_OF_PRODUCT_SUCCESS,
  payload,
});

export const getOrderOfProductFail = (error: any): IAction<any> => ({
  type: NotificationActionTypes.GET_ORDER_OF_PRODUCT_FAIL,
  error,
});

// get price change lines
export const getPriceChangeLines = (payload?: any): IAction<any> => ({
  type: NotificationActionTypes.GET_PRICE_CHANGE_LINES,
  payload
});

export const getPriceChangeLinesSuccess = (payload?: any): IAction<any> => ({
  type: NotificationActionTypes.GET_PRICE_CHANGE_LINES_SUCCESS,
  payload,
});

export const getPriceChangeLinesFail = (error: any): IAction<any> => ({
  type: NotificationActionTypes.GET_PRICE_CHANGE_LINES_FAIL,
  error,
});

// upload stock status
export const uploadPriceNotification = (payload?: any): IAction<any> => ({
  type: NotificationActionTypes.UPLOAD_PRICE_NOFITICATION,
  payload
});

export const uploadPriceNotificationSuccess = (payload?: any): IAction<any> => ({
  type: NotificationActionTypes.UPLOAD_PRICE_NOFITICATION_SUCCESS,
  payload,
});

export const uploadPriceNotificationFail = (error: any): IAction<any> => ({
  type: NotificationActionTypes.UPLOAD_PRICE_NOFITICATION_FAIL,
  error,
});

// download Draft To File
export const downloadDraftToFile = (payload?: any): IAction<any> => ({
  type: NotificationActionTypes.DOWNLOAD_DRAFT_TO_FILE,
  payload
});

export const downloadDraftToFileSuccess = (payload?: any): IAction<any> => ({
  type: NotificationActionTypes.DOWNLOAD_DRAFT_TO_FILE_SUCCESS,
  payload,
});

export const downloadDraftToFileFail = (error: any): IAction<any> => ({
  type: NotificationActionTypes.DOWNLOAD_DRAFT_TO_FILE_FAIL,
  error,
});

// upload stock status
export const importNotificationHistory = (payload?: any): IAction<any> => ({
  type: NotificationActionTypes.IMPORT_NOTIFICATION_HISTORY,
  payload
});

export const importNotificationHistorySuccess = (payload?: any): IAction<any> => ({
  type: NotificationActionTypes.IMPORT_NOTIFICATION_HISTORY_SUCCESS,
  payload,
});

export const importNotificationHistoryFail = (error: any): IAction<any> => ({
  type: NotificationActionTypes.IMPORT_NOTIFICATION_HISTORY_FAIL,
  error,
});

// GET DRAFT
export const getDraftRecord = (payload?: any): IAction<any> => ({
  type: NotificationActionTypes.GET_DRAFT_RECORD,
  payload
});

export const getDraftRecordSuccess = (payload?: any): IAction<any> => ({
  type: NotificationActionTypes.GET_DRAFT_RECORD_SUCCESS,
  payload,
});

export const getDraftRecordFail = (error: any): IAction<any> => ({
  type: NotificationActionTypes.GET_DRAFT_RECORD_FAIL,
  error,
});

// get noti d�tail
export const getNotificationDetail = (payload?: any): IAction<any> => ({
  type: NotificationActionTypes.GET_NOTIFICATION_DETAIL,
  payload
});

export const getNotificationDetailSuccess = (payload?: any): IAction<any> => ({
  type: NotificationActionTypes.GET_NOTIFICATION_DETAIL_SUCCESS,
  payload,
});

export const getNotificationDetailFail = (error: any): IAction<any> => ({
  type: NotificationActionTypes.GET_NOTIFICATION_DETAIL_FAIL,
  error,
});

// delete draft
export const deleteDraftRecord = (payload?: any): IAction<any> => ({
  type: NotificationActionTypes.DELETE_DRAFT_RECORD,
  payload
});

export const deleteDraftRecordSuccess = (payload?: any): IAction<any> => ({
  type: NotificationActionTypes.DELETE_DRAFT_RECORD_SUCCESS,
  payload,
});

export const deleteDraftRecordFail = (error: any): IAction<any> => ({
  type: NotificationActionTypes.DELETE_DRAFT_RECORD_FAIL,
  error,
});

// delete notificaitons
export const deleteNotifications = (payload?: any): IAction<any> => ({
  type: NotificationActionTypes.DELETE_NOTIFICATIONS,
  payload
});

export const deleteNotificationsSuccess = (payload?: any): IAction<any> => ({
  type: NotificationActionTypes.DELETE_NOTIFICATIONS_SUCCESS,
  payload,
});

export const deleteNotificationsFail = (error: any): IAction<any> => ({
  type: NotificationActionTypes.DELETE_NOTIFICATIONS_FAIL,
  error,
});

// update notification status
export const updateNotificationStatus = (payload?: any): IAction<any> => ({
  type: NotificationActionTypes.UPDATE_NOTIFICATION_STATUS,
  payload
});

export const updateNotificationStatusSuccess = (payload?: any): IAction<any> => ({
  type: NotificationActionTypes.UPDATE_NOTIFICATION_STATUS_SUCCESS,
  payload,
});

export const updateNotificationStatusFail = (error: any): IAction<any> => ({
  type: NotificationActionTypes.UPDATE_NOTIFICATION_STATUS_FAIL,
  error,
});

// search discontinued products
export const searchDiscontinued = (payload?: any): IAction<any> => ({
  type: NotificationActionTypes.SEARCH_DISCONTINUED_PRODUCTS,
  payload
});

export const searchDiscontinuedSuccess = (payload?: any): IAction<any> => ({
  type: NotificationActionTypes.SEARCH_DISCONTINUED_PRODUCTS_SUCCESS,
  payload,
});

export const searchDiscontinuedFail = (error: any): IAction<any> => ({
  type: NotificationActionTypes.SEARCHT_DISCONTINUED_PRODUCTS_FAIL,
  error,
});

// search discontinued products
export const searchReadyDiscontinued = (payload?: any): IAction<any> => ({
  type: NotificationActionTypes.SEARCH_READY_DISCONTINUED_PRODUCTS,
  payload
});

export const searchReadyDiscontinuedSuccess = (payload?: any): IAction<any> => ({
  type: NotificationActionTypes.SEARCH_READY_DISCONTINUED_PRODUCTS_SUCCESS,
  payload,
});

export const searchReadyDiscontinuedFail = (error: any): IAction<any> => ({
  type: NotificationActionTypes.SEARCHT_READY_DISCONTINUED_PRODUCTS_FAIL,
  error,
});

// get discontinued products
export const getDiscontinuedProducts = (payload?: any): IAction<any> => ({
  type: NotificationActionTypes.GET_DISCONTINUED_PRODUCTS,
  payload
});

export const getDiscontinuedProductsSuccess = (payload?: any): IAction<any> => ({
  type: NotificationActionTypes.GET_DISCONTINUED_PRODUCTS_SUCCESS,
  payload,
});

export const getDiscontinuedProductsFail = (error: any): IAction<any> => ({
  type: NotificationActionTypes.GET_DISCONTINUED_PRODUCTS_FAIL,
  error,
});

// history discontinued products
export const historyDiscontinuedProducts = (payload?: any): IAction<any> => ({
  type: NotificationActionTypes.HISTORY_DISCONTINUED_PRODUCTS,
  payload
});

export const historyDiscontinuedProductsSuccess = (payload?: any): IAction<any> => ({
  type: NotificationActionTypes.HISTORY_DISCONTINUED_PRODUCTS_SUCCESS,
  payload,
});

export const historyDiscontinuedProductsFail = (error: any): IAction<any> => ({
  type: NotificationActionTypes.HISTORY_DISCONTINUED_PRODUCTS_FAIL,
  error,
});

// count warning products
export const countWarningProducts = (payload?: any): IAction<any> => ({
  type: NotificationActionTypes.COUNT_WARNING_PRODUCTS,
  payload
});

export const countWarningProductsSuccess = (payload?: any): IAction<any> => ({
  type: NotificationActionTypes.COUNT_WARNING_PRODUCTS_SUCCESS,
  payload,
});

export const countWarningProductsFail = (error: any): IAction<any> => ({
  type: NotificationActionTypes.COUNT_WARNING_PRODUCTS_FAIL,
  error,
});

// create discontinued products
export const createDiscontinuedProducts = (payload?: any): IAction<any> => ({
  type: NotificationActionTypes.CREATE_DISCONTINUED_PRODUCTS,
  payload
});

export const createDiscontinuedProductsSuccess = (payload?: any): IAction<any> => ({
  type: NotificationActionTypes.CREATE_DISCONTINUED_PRODUCTS_SUCCESS,
  payload,
});

export const createDiscontinuedProductsFail = (error: any): IAction<any> => ({
  type: NotificationActionTypes.CREATE_DISCONTINUED_PRODUCTS_FAIL,
  error,
});

// upload discontinued products
export const uploadDiscontinuedProducts = (payload?: any): IAction<any> => ({
  type: NotificationActionTypes.UPLOAD_DISCONTINUED_PRODUCTS,
  payload
});

export const uploadDiscontinuedProductsSuccess = (payload?: any): IAction<any> => ({
  type: NotificationActionTypes.UPLOAD_DISCONTINUED_PRODUCTS_SUCCESS,
  payload,
});

export const uploadDiscontinuedProductsFail = (error: any): IAction<any> => ({
  type: NotificationActionTypes.UPLOAD_DISCONTINUED_PRODUCTS_FAIL,
  error,
});
// update discontinued line
export const updateDiscontinueLine = (payload?: any): IAction<any> => ({
  type: NotificationActionTypes.UPDATE_DISCONTINUED_LINE,
  payload
});

export const updateDiscontinueLineSuccess = (payload?: any): IAction<any> => ({
  type: NotificationActionTypes.UPDATE_DISCONTINUED_LINE_SUCCESS,
  payload,
});

export const updateDiscontinueLineFail = (error: any): IAction<any> => ({
  type: NotificationActionTypes.UPDATE_DISCONTINUED_LINE_FAIL,
  error,
});

// delete discontinued lines
export const deleteDiscontinueLines = (payload?: any): IAction<any> => ({
  type: NotificationActionTypes.DELETE_DISCONTINUED_LINES,
  payload
});

export const deleteDiscontinueLinesSuccess = (payload?: any): IAction<any> => ({
  type: NotificationActionTypes.DELETE_DISCONTINUED_LINES_SUCCESS,
  payload,
});

export const deleteDiscontinueLinesFail = (error: any): IAction<any> => ({
  type: NotificationActionTypes.DELETE_DISCONTINUED_LINES_FAIL,
  error,
});

// save promotion
export const savePromotion = (payload?: any): IAction<any> => ({
  type: NotificationActionTypes.SAVE_PROMOTION,
  payload
});

export const savePromotionSuccess = (payload?: any): IAction<any> => ({
  type: NotificationActionTypes.SAVE_PROMOTION_SUCCESS,
  payload,
});

export const savePromotionFail = (error: any): IAction<any> => ({
  type: NotificationActionTypes.SAVE_PROMOTION_FAIL
});

// save promotion
export const calculateAnnouncementTarget = (payload?: any): IAction<any> => ({
  type: NotificationActionTypes.CALCULATE_ANNOUNCEMENT_TARGET,
  payload
});

export const calculateAnnouncementTargetSuccess = (payload?: any): IAction<any> => ({
  type: NotificationActionTypes.CALCULATE_ANNOUNCEMENT_TARGET_SUCCESS,
  payload,
});

export const calculateAnnouncementTargetFail = (error: any): IAction<any> => ({
  type: NotificationActionTypes.CALCULATE_ANNOUNCEMENT_TARGET_FAIL
});

// download discontinued lines
export const downloadDiscontinueLines = (payload?: any): IAction<any> => ({
  type: NotificationActionTypes.DOWNLOAD_DISCONTINUED_LINES,
  payload
});

export const downloadDiscontinueLinesSuccess = (payload?: any): IAction<any> => ({
  type: NotificationActionTypes.DOWNLOAD_DISCONTINUED_LINES_SUCCESS,
  payload,
});

export const downloadDiscontinueLinesFail = (error: any): IAction<any> => ({
  type: NotificationActionTypes.DOWNLOAD_DISCONTINUED_LINES_FAIL,
  error,
});

// get segments
export const getSegments = (payload?: any): IAction<any> => ({
    type: NotificationActionTypes.GET_SEGMENTS,
    payload
});

export const getSegmentsSuccess = (payload?: any): IAction<any> => ({
    type: NotificationActionTypes.GET_SEGMENTS_SUCCESS,
    payload,
});

export const getSegmentsFail = (error: any): IAction<any> => ({
    type: NotificationActionTypes.SEARCH_SEGMENTS_FAIL,
    error,
});

// add segment
export const addSegment = (payload?: any): IAction<any> => ({
    type: NotificationActionTypes.ADD_SEGMENT,
    payload
});

export const addSegmentSuccess = (payload?: any): IAction<any> => ({
    type: NotificationActionTypes.ADD_SEGMENT_SUCCESS,
    payload,
});

export const addSegmentFail = (error: any): IAction<any> => ({
    type: NotificationActionTypes.ADD_SEGMENT_FAIL,
    error,
});

// caculate audience size
export const calculateAudienceSize = (payload?: any): IAction<any> => ({
    type: NotificationActionTypes.CACULATE_AUDIENCE_SIZE,
    payload
});

export const calculateAudienceSizeSuccess = (payload?: any): IAction<any> => ({
    type: NotificationActionTypes.CACULATE_AUDIENCE_SIZE_SUCCESS,
    payload,
});

export const calculateAudienceSizeFail = (error: any): IAction<any> => ({
    type: NotificationActionTypes.CACULATE_AUDIENCE_SIZE_FAIL,
    error,
});

// delete segment
export const deleteSegment = (payload?: any): IAction<any> => ({
    type: NotificationActionTypes.DELETE_SEGMENT,
    payload
});

export const deleteSegmentSuccess = (payload?: any): IAction<any> => ({
    type: NotificationActionTypes.DELETE_SEGMENT_SUCCESS,
    payload,
});

export const deleteSegmentFail = (error: any): IAction<any> => ({
    type: NotificationActionTypes.DELETE_SEGMENT_FAIL,
    error,
});

// handle setting notification
export const settingNotifications = (payload: SettingNotificationPayload): IAction<any> => ({
  type: NotificationActionTypes.SETTING_NOTIFICATIONS,
  payload,
});

export const settingNotificationsSuccess = (payload?: any): IAction<any> => ({
  type: NotificationActionTypes.SETTING_NOTIFICATIONS_SUCCESS,
  payload,
});

export const settingNotificationsFail = (error: any): IAction<any> => ({
  type: NotificationActionTypes.SETTING_NOTIFICATIONS_FAIL,
  error,
});

// handle get setting notifications
export const getSettingNotifications = (): IAction<any> => ({
  type: NotificationActionTypes.GET_SETTING_NOTIFICATIONS,
});

export const getSettingNotificationsSuccess = (payload?: any): IAction<any> => ({
  type: NotificationActionTypes.GET_SETTING_NOTIFICATIONS_SUCCESS,
  payload,
});

export const getSettingNotificationsFail = (error: any): IAction<any> => ({
  type: NotificationActionTypes.GET_SETTING_NOTIFICATIONS_FAIL,
  error,
});