import {SitecoreResource} from '../../../gateway/api/resource';
import ResponseModel from '../../../../models/response';
import { getConfig } from '../../../setting/graphConfig';
import ApiGateway from '../../../gateway/api';
import { ISearchBrands, ISearchNotifications } from '../../../../presentation/redux/actions/interfaces/notification.types';
import { SettingNotificationPayload } from '../../../../presentation/redux/actions/types/notification-types';

const saveNotification = (payload: any): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const notificationResource = SitecoreResource.Notification.SaveNotification();

    return apiGateway.doPostRequest(notificationResource, payload);
};

const savePartialNotification = (payload: any): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const notificationResource = SitecoreResource.Notification.SavePartialNotification();

    return apiGateway.doPostRequest(notificationResource, payload);
};

const registerPriceChanges = (payload: any): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const notificationResource = SitecoreResource.Notification.RegisterPriceChanges();

    return apiGateway.doPostRequest(notificationResource, payload);
};

const pushNotification = (payload: any): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const notificationResource = SitecoreResource.Notification.PushNotification();

    return apiGateway.doPostRequest(notificationResource, payload);
};

const searchBrands = (payload: ISearchBrands): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const notificationResource = SitecoreResource.Notification.SearchBrands(payload);

    return apiGateway.doGetRequest(notificationResource);
};

const SearchBanners = (payload: ISearchBrands): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const notificationResource = SitecoreResource.Notification.SearchBanners(payload);

    return apiGateway.doGetRequest(notificationResource);
};

const searchNotifications = (payload: ISearchNotifications): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const notificationResource = SitecoreResource.Notification.searchNotifications(payload);

    return apiGateway.doGetRequest(notificationResource);
};

const searchPriceChanges = (payload: ISearchNotifications): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const notificationResource = SitecoreResource.Notification.searchPriceChanges(payload);

    return apiGateway.doGetRequest(notificationResource);
};

const searchTags = (payload: any): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const notificationResource = SitecoreResource.Notification.SearchTags(payload);

    return apiGateway.doGetRequest(notificationResource);
};

const deleteTags = (payload: any): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const notificationResource = SitecoreResource.Notification.deleteTags(payload);

    return apiGateway.doDeleteRequest(notificationResource);
};

const addTagForSakaya = (payload: any): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const notificationResource = SitecoreResource.Notification.AddTagForSakaya();

    return apiGateway.doPostRequest(notificationResource, payload);
};

const getCategories = (): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const notificationResource = SitecoreResource.Notification.GetCategories();

    return apiGateway.doGetRequest(notificationResource);
};

const updateTagForSakaya = (payload: any): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const notificationResource = SitecoreResource.Notification.UpdateTagForSakaya();

    return apiGateway.doPutRequest(notificationResource, payload);
};

const addTagForCustomer = (payload: any): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const notificationResource = SitecoreResource.Notification.AddTagForCustomer();

    return apiGateway.doPostRequest(notificationResource, payload);
};

const deleteTagForCustomer = (payload: any): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const notificationResource = SitecoreResource.Notification.DeleteTagForCustomer(payload);

    return apiGateway.doDeleteRequest(notificationResource);
};

const importCustomerTags = (payload: any): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const notificationResource = SitecoreResource.Notification.ImportCustomerTags();

    return apiGateway.doPostRequest(notificationResource, payload);
};

const populateAddress = (payload: any): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const notificationResource = SitecoreResource.Notification.PopulateAddress(payload);

    return apiGateway.doGetRequest(notificationResource);
};

const getOrderOfProduct = (payload: any): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const notificationResource = SitecoreResource.Notification.GetOrderOfProduct(payload);

    return apiGateway.doGetRequest(notificationResource);
};

const getPriceChangeLines = (payload: any): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const notificationResource = SitecoreResource.Notification.GetPriceChangeLines();

    return apiGateway.doPutRequest(notificationResource, payload);
};

const uploadPriceNotification = (payload: any): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const notificationResource = SitecoreResource.Notification.UploadPriceNotification();

    return apiGateway.doPostRequest(notificationResource, payload);
};

const importNotificationHistory = (payload: any): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const notificationResource = SitecoreResource.Notification.ImportNotificationHistory(payload);

    return apiGateway.doGetRequest(notificationResource);
};

const downloadDraftToFile = (payload: any): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const notificationResource = SitecoreResource.Notification.DownloadDraftToFile(payload);

    return apiGateway.doGetRequest(notificationResource);
};

const downloadNotification = async (notificationId: string): Promise<ResponseModel<any>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const notificationResource = SitecoreResource.Notification.DownloadNotification(notificationId);

    return await apiGateway.doGetRequest(notificationResource);
}


const getDraftRecord = (payload: any): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const notificationResource = SitecoreResource.Notification.GetDraftRecord(payload);

    return apiGateway.doGetRequest(notificationResource);
};

const getNotificationDetail = (payload: any): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const notificationResource = SitecoreResource.Notification.NotificationDetail(payload);

    return apiGateway.doGetRequest(notificationResource);
};

const deleteDraftRecord = (payload: any): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const notificationResource = SitecoreResource.Notification.DeleteDraftRecord(payload);

    return apiGateway.doDeleteRequest(notificationResource);
};

const deleteNotifications = (payload: any): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const notificationResource = SitecoreResource.Notification.DeleteNotifications(payload);

    return apiGateway.doDeleteRequest(notificationResource);
};

const updateNotificationStatus = (payload: any): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const notificationResource = SitecoreResource.Notification.UpdateNotificationStatus(payload);

    return apiGateway.doPutRequest(notificationResource);
};

const searchDiscontinued = (payload: any): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const notificationResource = SitecoreResource.Notification.searchDiscontinued(payload);

    return apiGateway.doGetRequest(notificationResource);
};

const searchReadyDiscontinued = (payload: any): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const notificationResource = SitecoreResource.Notification.searchReadyDiscontinued(payload);

    return apiGateway.doGetRequest(notificationResource);
};

const getDiscontinuedProducts = (payload: any): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const notificationResource = SitecoreResource.Notification.GetDiscontinuedProducts(payload);

    return apiGateway.doGetRequest(notificationResource);
};

const historyDiscontinuedProducts = (payload: any): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const notificationResource = SitecoreResource.Notification.HistoryDiscontinuedProducts(payload);

    return apiGateway.doGetRequest(notificationResource);
};

const countWarningProduct = (payload: any): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const notificationResource = SitecoreResource.Notification.CountWarningProduct(payload);

    return apiGateway.doGetRequest(notificationResource);
};

const createDiscontinuedProducts = (payload: any): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const notificationResource = SitecoreResource.Notification.CreateDiscontinuedProducts();

    return apiGateway.doPostRequest(notificationResource, payload);
};

const uploadDiscontinuedProduct = (payload: any): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const notificationResource = SitecoreResource.Notification.UploadDiscontinuedProduct();

    return apiGateway.doPostRequest(notificationResource, payload);
};

const updateDiscontinueLine = (payload: any): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const notificationResource = SitecoreResource.Notification.UpdateDiscontinueLine();

    return apiGateway.doPutRequest(notificationResource, payload);
};

const deleteDiscontinueLine = (payload: any): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const notificationResource = SitecoreResource.Notification.DeleteDiscontinueLine(payload);

    return apiGateway.doDeleteRequest(notificationResource);
};

const savePromotion = (payload: any): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const notificationResource = SitecoreResource.Notification.SavePromotion();

	return apiGateway.doPostRequest(notificationResource, payload);
};

const calculateAnnouncementTarget = (payload: any): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const notificationResource = SitecoreResource.Notification.CalculateAnnouncementTarget();

	return apiGateway.doPutRequest(notificationResource, payload);
};

const downloadDiscontinueLine = (payload: any): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const notificationResource = SitecoreResource.Notification.DownloadDiscontinueLine();

    return apiGateway.doPostRequest(notificationResource, payload);
};

const searchSegments = (payload: any): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const notificationResource = SitecoreResource.Notification.SearchSegments(payload);

    return apiGateway.doGetRequest(notificationResource);
};

const addSegment = (payload: any): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const notificationResource = SitecoreResource.Notification.AddSegment();

    return apiGateway.doPostRequest(notificationResource, payload);
};

const calculateAudienceSize = (payload: any): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const notificationResource = SitecoreResource.Notification.CalculateAudienceSize();

    return apiGateway.doPostRequest(notificationResource, payload);
};

const deleteSegment = (payload: any): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const notificationResource = SitecoreResource.Notification.DeleteSegment(payload);

    return apiGateway.doDeleteRequest(notificationResource);
};

const settingNotifications = (payload: SettingNotificationPayload): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const notificationResource = SitecoreResource.Notification.SettingNotifications();
   
    return apiGateway.doPostRequest(notificationResource, payload);
};

const getSettingNotifications = (): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const notificationResource = SitecoreResource.Notification.GetSettingNotifications();
   
    return apiGateway.doGetRequest(notificationResource);
};

export default {
    countWarningProduct,
    updateDiscontinueLine,
    deleteDiscontinueLine,
    downloadDiscontinueLine,
    saveNotification,
    savePartialNotification,
    registerPriceChanges,
    pushNotification,
    searchBrands,
    SearchBanners,
    searchNotifications,
    searchPriceChanges,
    searchTags,
    getCategories,
    deleteTags,
    addTagForSakaya,
    updateTagForSakaya,
    addTagForCustomer,
    deleteTagForCustomer,
    importCustomerTags,
    populateAddress,
    getOrderOfProduct,
    getPriceChangeLines,
    uploadPriceNotification,
    importNotificationHistory,
    downloadDraftToFile,
    getDraftRecord,
    getNotificationDetail,
    deleteDraftRecord,
    deleteNotifications,
    updateNotificationStatus,
    searchDiscontinued,
    searchReadyDiscontinued,
    getDiscontinuedProducts,
    historyDiscontinuedProducts,
    createDiscontinuedProducts,
    uploadDiscontinuedProduct,
    downloadNotification,
    savePromotion,
    calculateAnnouncementTarget,
    searchSegments,
    addSegment,
    calculateAudienceSize,
    deleteSegment,
    settingNotifications,
    getSettingNotifications
};
