import { combineReducers } from "redux";

import { reducer as toastr } from "react-redux-toastr";
import { usersReducer } from "../reducers/users";
import { customersReducer } from "../reducers/customers";
import { ordersReducer } from "../reducers/orders";
import { productsReducer } from "../reducers/products";
import { saleReducer } from "../reducers/sales";
import { notificationReducer } from "../reducers/notification";
import { invoicesReducer } from "../reducers/invoices";
import { tempProductReducer } from "../reducers/tempProducts";
import { flyersReducer } from "../reducers/flyers";
import { mitsubishiReducer } from "../reducers/mitsubishi";

export const rootReducer = combineReducers({
  toastr,
  usersReducer,
  customersReducer,
  ordersReducer,
  productsReducer,
  saleReducer,
  notificationReducer,
  invoicesReducer,
  tempProductReducer,
  flyersReducer,
  mitsubishiReducer
});
