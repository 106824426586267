import { combineEpics } from "redux-observable";
import { getFileImportMitsubishiEpic } from "./getFileImportMitsubishiEpic";
import { importPricesEpic } from "./importFilesEpic";
import { importFilesHistoryEpic } from "./importFilesHistoryEpic";
import { getMitsubishiDetailErrorEpic } from "./getDetailErrorEpic";

export const mitsubishiEpic = combineEpics(
    importFilesHistoryEpic,
    importPricesEpic,
    getFileImportMitsubishiEpic,
    getMitsubishiDetailErrorEpic
);