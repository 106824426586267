import { ofType } from "redux-observable";
import { Observable } from "rxjs";
import { exhaustMap, switchMap } from "rxjs/operators";
import { IAction } from "../../../../shared/interfaces/common";
import { importFilesFail, importFilesSuccess, MitsubishiActionTypes } from "../../actions/mitsubishi/mitsubishi.action";
import ImportFilesCase from "../../../../domain/usecases/mitsubishi/ImportFiles";

export const importPricesEpic = (action$: any, state$: any) =>
    action$.pipe(
        ofType(MitsubishiActionTypes.IMPORT_MITSUBISHI_FILES),
        exhaustMap((action: IAction<any>) => {

            return new Observable(obs => {
                const { payload } = action;
                const usecase = new ImportFilesCase(payload);
                usecase.execute().then((responses: any) => {
                    let data = responses;
                    obs.next(importFilesSuccess(data.data));
                    obs.complete();
                }).catch(error => {
                    obs.next(importFilesFail(error.message));
                    obs.complete();
                });
            });
        })
    );
