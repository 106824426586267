import TempProductCloud from "./cloud";
import TempProductStorage from "./storage";
import CmsCloud from "./cms/index";
import { IDeleteTempProductPayload, ISaveTempProductPayload, ISearchProductsPayload, ISearchTempProductsPayload } from "../../../presentation/redux/actions/interfaces/tempProduct.types";

export default class TempProductRepository {
  public searchProducts = (payload: ISearchProductsPayload) => {
    return TempProductCloud.searchProducts(payload);
  };

  public saveTempProduct = (payload: ISaveTempProductPayload) => {
    return TempProductCloud.saveTempProduct(payload);
  };

  public deleteTempProduct = (payload: IDeleteTempProductPayload) => {
    return TempProductCloud.deleteTempProduct(payload);
  };

  public searchTempProducts = (payload: ISearchTempProductsPayload) => {
    return TempProductCloud.searchTempProducts(payload);
  };

  public getCategories = () => {
    return TempProductCloud.getCategories();
  };

  public getBrands = () => {
    return TempProductCloud.getBrands();
  };

  public importBatchProducts = (payload: any) => {
    return TempProductCloud.importBatchProducts(payload);
  };

  public importProductsHistories = (payload: any) => {
    return TempProductCloud.importProductsHistories(payload);
  };
}
