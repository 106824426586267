import { combineReducers } from "@reduxjs/toolkit";
import { importFilesReducer } from "./importFilesReducer";
import { getImportFileHistoryReducer } from "./getImportFilesHistoryReducer";
import { getFileImportFileReducer } from "./getFileImportFileReducer";
import { getDetailErrorReducer } from "./getDetailErrorReducer";

export const mitsubishiReducer = combineReducers({
    importFilesReducer,
    getImportFileHistoryReducer,
    getFileImportFileReducer,
    getDetailErrorReducer
});