import { ofType } from "redux-observable";
import { Observable } from "rxjs";
import { exhaustMap } from "rxjs/operators";
import { IAction } from "../../../../shared/interfaces/common";
import { getFileImportMitsubishiFail, getFileImportMitsubishiSuccess, MitsubishiActionTypes } from "../../actions/mitsubishi/mitsubishi.action";
import GetFileImportMitsubishiUseCase from "../../../../domain/usecases/mitsubishi/GetFileImportMitsubishi";

export const getFileImportMitsubishiEpic = (action$: any, state$: any) =>
    action$.pipe(
        ofType(MitsubishiActionTypes.GET_FILE_AWS),
        exhaustMap((action: IAction<any>) => {

            return new Observable(obs => {
                const { payload } = action;
                const usecase = new GetFileImportMitsubishiUseCase(payload);
                usecase.execute().then((responses: any) => {
                    let data = responses;
                    obs.next(getFileImportMitsubishiSuccess(data.data));
                    obs.complete();
                }).catch(error => {
                    obs.next(getFileImportMitsubishiFail(error.message));
                    obs.complete();
                });
            });
        })
    );


