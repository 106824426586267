import { combineEpics } from "redux-observable";
import { deleteTempProductsEpic } from "./deleteTempProductsEpic";
import { searchProductsEpic } from "./searchProductsEpic";
import { saveTempProductEpic } from "./saveTempProductEpic";
import { searchTempProductsEpic } from "./searchTempProductsEpic";
import { getCategoriesEpic } from "./getCategoriesEpic";
import { getBrandsEpic } from "./getBrandsEpic";
import { importBatchProductsEpic } from "./importBatchProductsEpic";
import { importProductsHistoriesEpic } from "./importProductsHistoriesEpic";

export const tempProductsEpic = combineEpics(
    deleteTempProductsEpic,
    searchProductsEpic,
    saveTempProductEpic,
    searchTempProductsEpic,
    getCategoriesEpic,
    getBrandsEpic,
    importBatchProductsEpic,
    importProductsHistoriesEpic
);
